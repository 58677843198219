define('lcms/page/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    modelLoader: Ember.inject.service(),
    lcms: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    model: function model(params) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var slug = params.id;
        if (slug === undefined || slug === null) {
          slug = 'index';
        } else if (slug.length > 5 && slug.substring(slug.length - 5) == '.html') {
          slug = slug.substring(0, slug.length - 5);
        }
        _this.get('modelLoader').ensure_model('lcms', 'page', 'pages').then(function () {
          _this.get('store').findRecord('lcms/page', slug).then(resolve, reject);
        }, reject);
      });
    },
    afterModel: function afterModel(model, transition) {
      this.set('lcms.currentPage', model.get('id'));
    },


    actions: {
      addContent: function addContent(section, controller) {
        var content = this.get('store').createRecord('lcms/content', {
          section: section,
          ordernum: section.get('contents.length'),
          content_data: {}
        });
        controller.set('edited', 'content');
        controller.set('editing', content);
      },
      addSection: function addSection(page, controller) {
        var section = this.get('store').createRecord('lcms/section', {
          page: page,
          ordernum: page.get('sections.length')
        });
        controller.set('edited', 'section');
        controller.set('editing', section);
      },
      reorderItems: function reorderItems(items) {
        var _this2 = this;

        var index = 0;
        var promises = [];
        items.forEach(function (item) {
          if (item.get('ordernum') !== index) {
            item.set('ordernum', index);
            promises.push(item.save());
          }
          index++;
        });
        Ember.RSVP.allSettled(promises).then(function (responses) {
          var success = true;
          responses.forEach(function (response) {
            success = success && response.state === 'fulfilled';
          });
          if (success) {
            _this2.get('flashMessages').success('Reorder complete');
          } else {
            _this2.get('flashMessages').danger('Unable to reorder');
          }
        });
      }
    }
  });
});