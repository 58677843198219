define('lcms/components/edit-section/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    fields: [{
      label: 'Anchor type',
      name: 'category',
      widget: 'select',
      extra: {
        choices: [{ value: 'regular', label: 'regular' }, { value: 'special', label: 'special' }, { value: 'anchor', label: 'anchor' }]
      }
    }, {
      label: 'Slug',
      name: 'slug',
      widget: 'text',
      extra: {
        display_condition: {
          operator: 'not-eq',
          value: 'regular',
          property_path: 'category'
        }
      }
    }, {
      label: 'Class',
      name: 'klass',
      widget: 'text'
    }, {
      label: 'Title',
      name: 'title',
      widget: 'text'
    }, {
      widget: 'select',
      label: 'Type',
      name: 'type',
      extra: {
        choices: [{
          label: 'Full-width grid',
          value: 'F'
        }, {
          label: 'Contained grid',
          value: 'C'
        }, {
          label: 'Full-width flex',
          value: 'FF'
        }, {
          label: 'Contained flex',
          value: 'FC'
        }]
      }
    }, {
      widget: 'select',
      label: 'Background type',
      name: 'background_type',
      extra: {
        choices: [{
          label: 'None',
          value: 'N'
        }, {
          label: 'Color',
          value: 'C'
        }, {
          label: 'Image',
          value: 'I'
        }]
      }
    }, {
      label: 'Background color',
      name: 'background_color',
      widget: 'color',
      extra: {
        display_condition: {
          operator: 'eq',
          property_path: 'background_type',
          value: 'C'
        }
      }
    }, {
      label: 'Background image',
      name: 'background_image',
      widget: 'image',
      extra: {
        display_condition: {
          operator: 'eq',
          property_path: 'background_type',
          value: 'I'
        }
      }
    }, {
      label: 'Background position',
      name: 'background_attachment',
      widget: 'select',
      extra: {
        choices: [{ label: 'top left', value: 'top left' }, { label: 'top center', value: 'top center' }, { label: 'top right', value: 'top right' }, { label: 'center left', value: 'center left' }, { label: 'center center', value: 'center center' }, { label: 'center right', value: 'center right' }, { label: 'bottom left', value: 'bottom left' }, { label: 'bottom center', value: 'bottom center' }, { label: 'bottom right', value: 'bottom right' }],
        display_condition: {
          operator: 'eq',
          property_path: 'background_type',
          value: 'I'
        }
      }
    }, {
      label: 'Background scale',
      name: 'background_size',
      widget: 'select',
      extra: {
        choices: [{ value: 'auto', label: 'original' }, { value: 'contain', label: 'contain' }, { value: 'cover', label: 'cover' }],
        display_condition: {
          operator: 'eq',
          property_path: 'background_type',
          value: 'I'
        }
      }
    }, {
      label: 'Background Repeats',
      name: 'background_repeat',
      widget: 'select',
      extra: {
        choices: [{ value: 'no-repeat', label: 'no-repeat' }, { value: 'repeat-x', label: 'repeat-x' }, { value: 'repeat-y', label: 'repeat-y' }, { value: 'repeat', label: 'repeat' }],
        display_condition: {
          operator: 'eq',
          property_path: 'hasBgRepeat',
          value: true
        }
      }
    }, {
      label: 'Background Containment',
      name: 'background_container_size',
      widget: 'select',
      extra: {
        choices: [{ value: 'full-width', label: 'full-width' }, { value: 'contained', label: 'contained' }],
        display_condition: {
          operator: 'eq',
          property_path: 'hasBgContain',
          value: true
        }
      }
    }],

    unsaved: Ember.computed('model', 'model.isDirty', 'model.isNew', function () {
      var model = this.get('model');
      if (model === undefined) {
        return false;
      }
      return model.get('isNew') || model.get('isDirty');
    })
  });
});