define('lcms/components/cl-items', ['exports', 'ember-cli-crudities/components/cl-items'], function (exports, _clItems) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _clItems.default;
    }
  });
});