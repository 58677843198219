define('lcms/application/route', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    authenticator: 'authenticator:django',
    actions: {
      authenticate: function authenticate(credentials) {
        var session = this.get('session');
        if (credentials.identification && credentials.password) {
          session.authenticate(this.get('authenticator'), credentials).then(function () {
            session.set('loginError', false);
          }, function () {
            session.set('loginError', "Invalid credentials. Please retry.");
          });
        }
      },
      invalidate: function invalidate() {
        this.get('session').invalidate();
      }
    }
  });
});