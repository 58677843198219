define('lcms/components/add-content/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    lcms: Ember.inject.service(),
    classNames: ['col-xs-12', 'add-btn-container']
  });
});