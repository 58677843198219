define('lcms/components/form-fa/component', ['exports', 'ember-cli-crudities/components/form-select', 'lcms/components/form-fa/icons'], function (exports, _formSelect, _icons) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _formSelect.default.extend({
    choices: _icons.default,
    selectedItem: Ember.computed.alias('value')
  });
});