define('lcms/authenticators/django', ['exports', 'ember-simple-auth/authenticators/base', 'lcms/mixins/request'], function (exports, _base, _request) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend(_request.default, {
    authenticate: function authenticate(credentials) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var data = { username: credentials.identification, password: credentials.password };
        _this.makeRequest(_this.serverAuthEndpoint + '/login/', data).then(function (response) {
          Ember.run(function () {
            resolve(response);
          });
        }, function (xhr /*, status, error */) {
          Ember.run(function () {
            reject(xhr.responseJSON || xhr.responseText);
          });
        });
      });
    },
    restore: function restore(data) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.makeRequest(_this2.serverAuthEndpoint + '/me/', null, 'GET').then(function () /* response */{
          resolve(data);
        }, function () /* xhr , status, error */{
          reject();
          _this2.invalidate();
        });
      });
    },
    invalidate: function invalidate() /* data */{
      var _this3 = this;

      function success(resolve) {
        resolve();
      }
      return new Ember.RSVP.Promise(function (resolve /*, reject */) {
        _this3.makeRequest(_this3.serverAuthEndpoint + '/logout/', {}).then(function () /* response */{
          Ember.run(function () {
            success(resolve);
          });
        }, function () /* xhr, status, error */{
          Ember.run(function () {
            success(resolve);
          });
        });
      });
    }
  });
});