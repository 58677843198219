define('lcms/services/lcms', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    currentPage: undefined,

    hasPage: Ember.computed('currentPage', function () {
      return !Ember.isEmpty(this.get('currentPage'));
    }),

    reordering: false,

    edited: undefined,
    editing: undefined
  });
});