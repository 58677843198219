define('lcms/router', ['exports', 'lcms/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType
  });

  exports.default = Router.map(function () {
    this.route('page', { path: '/:id' }, function () {
      this.route('settings');
    });
    this.route('homepage', { path: '/' });
    this.route('login');
    this.route('logout');
    this.route('admin');
  });
});