define('lcms/models/lcms/section', ['exports', 'lcms/models/base/lcms/section'], function (exports, _section) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _section.default.extend({
    sortContentsBy: ['ordernum'],
    sortedContents: Ember.computed.sort('contents', 'sortContentsBy'),

    hasBgContain: Ember.computed('type', 'background_type', function () {
      var type = this.get('type');
      if (type === 'F' || type === 'FF') {
        return false;
      }
      return this.get('background_type') !== 'N';
    }),

    hasBgRepeat: Ember.computed('background_type', 'background_size', function () {
      if (this.get('background_type') !== 'I') {
        return false;
      }
      return this.get('background_size') !== 'cover';
    })
  });
});