define('lcms/components/content-text/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    has_image: Ember.computed('model.content_image', 'model.content_data.image_position', function () {
      if (Ember.isEmpty(this.get('model.content_image'))) {
        return false;
      }
      var position = this.get('model.content_data.image_position');
      return position === 'l' || position === 'r' || position === 'c';
    })
  });
});