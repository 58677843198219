define('lcms/models/base/lcms/content', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    size: (0, _attr.default)('string'),

    special: (0, _attr.default)('string'),

    content_image: (0, _attr.default)('string'),

    content_file: (0, _attr.default)('string'),

    content_data: (0, _attr.default)(),

    ordernum: (0, _attr.default)('number'),

    updated: (0, _attr.default)('nullable'),

    __str__: (0, _attr.default)('string'),

    images: (0, _relationships.hasMany)('lcms/contentmedium', {
      async: true,
      inverse: 'content'
    }),

    section: (0, _relationships.belongsTo)('lcms/section', {
      async: true,
      inverse: 'contents'
    }),

    type: (0, _relationships.belongsTo)('lcms/contenttype', {
      async: true,
      inverse: null
    })

  });
});