define('lcms/page/settings/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      console.log('model params', params);
      var model = this.modelFor('page');
      console.log(model);
      return model;
    }
  });
});