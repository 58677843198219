define('lcms/components/editable-section/component', ['exports', 'lcms/mixins/editable-mixin'], function (exports, _editableMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_editableMixin.default, {
    type: 'section',

    id: Ember.computed('model.category', 'model.slug', function () {
      if (this.get('model.category') === 'special') {
        return this.get('model.slug');
      }
      return null;
    })
  });
});