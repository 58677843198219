define('lcms/components/add-section/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['col-xs-12'],
    lcms: Ember.inject.service()
  });
});