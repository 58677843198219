define('lcms/components/form-obj-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    sortBy: ['position'],
    sortedRecords: Ember.computed.sort('value', 'sortBy'),

    actions: {
      addRecord: function addRecord() {
        var value = [];
        if (!Ember.isEmpty(this.get('value'))) {
          this.get('value').forEach(function (record) {
            value.push(record);
          });
        }
        value.push(new Ember.Object({ position: value.length + 1 }));
        Ember.set(this.get('model'), this.get('property'), value);
      },
      reorderItems: function reorderItems(items) {
        items.forEach(function (item, index) {
          Ember.set(item, 'position', index + 1);
        });
      },
      deleteRecord: function deleteRecord(record) {
        var currentValue = this.get('model.' + this.get('property'));
        this.set('model.' + this.get('property'), currentValue.filter(function (item) {
          return item != record;
        }).map(function (item, index) {
          Ember.set(item, 'position', index + 1);
          return item;
        }));
      }
    }
  });
});