define('lcms/models/base/lcms/contentmedium', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    image: (0, _attr.default)('string'),

    ordernum: (0, _attr.default)('number'),

    __str__: (0, _attr.default)('string'),

    content: (0, _relationships.belongsTo)('lcms/content', {
      async: true,
      inverse: 'images'
    })

  });
});