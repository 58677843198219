define('lcms/components/form-secret', ['exports', 'ember-cli-crudities/components/form-secret'], function (exports, _formSecret) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _formSecret.default;
    }
  });
});