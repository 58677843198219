define('lcms/components/design-form/component', ['exports', 'lcms/mixins/request'], function (exports, _request) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_request.default, {
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    updating: false,

    fields: [{
      label: 'Text color',
      name: 'text_color',
      widget: 'color'
    }, {
      label: 'Body background color',
      name: 'body_bg',
      widget: 'color'
    }, {
      label: 'Primary color',
      name: 'brand_primary',
      widget: 'color'
    }, {
      label: 'Secondary color',
      name: 'brand_secondary',
      widget: 'color'
    }, {
      label: 'Link color',
      name: 'link_color',
      widget: 'color'
    }, {
      label: 'Main font',
      name: 'font_family_base',
      widget: 'select',
      extra: {
        choices: [{ value: '"Helvetica Neue", Helvetica, Arial, sans-serif', label: 'Helvetica' }, { value: 'Dosis', label: 'Dosis' }, { value: 'GrutchShaded', label: 'Grutch shaded' }, { value: 'Italiano', label: 'Italiano' }, { value: 'Lane', label: 'Lane' }, { value: 'Parisienne', label: 'Parisienne' }]
      }
    }, {
      label: 'Text size',
      name: 'font_size',
      widget: 'number'
    }, {
      label: 'Titles font',
      name: 'headings_font_family',
      widget: 'select',
      extra: {
        choices: [{ value: 'inherit', label: 'Same as text' }, { value: '"Helvetica Neue", Helvetica, Arial, sans-serif', label: 'Helvetica' }, { value: 'Dosis', label: 'Dosis' }, { value: 'GrutchShaded', label: 'Grutch shaded' }, { value: 'Italiano', label: 'Italiano' }, { value: 'Lane', label: 'Lane' }, { value: 'Parisienne', label: 'Parisienne' }]
      }
    }],

    custom_css_field: {
      label: 'Custom SCSS',
      name: 'custom_scss',
      widget: 'textarea'
    },

    init: function init() {
      this._super();
      var model = this.get('store').peekAll('sites/site').objectAt(0);
      this.set('model', model);
      this.set('cached', model.serialize());
    },
    updateCss: function updateCss() {
      var _this = this;

      var serialized = this.get('model').serialize();
      if (JSON.stringify(serialized) !== JSON.stringify(this.get('cached'))) {
        this.set('cached', serialized);
        this.makeRequest('/' + this.apiRoot + '/sites/sites/0/preview/', this.get('model').serialize()).then(function (data) {
          data.json().then(function (json) {
            window.$('#site_css').remove();
            var css = window.$('<link>', {
              rel: 'stylesheet',
              type: 'text/css',
              href: json.css,
              id: 'site_css',
              onload: function onload() {
                _this.set('updating', false);
              }
            })[0];
            window.$('head').append(css);
          });
        }, function (err) {
          console.error('failed css', err);
          _this.set('updating', false);
          _this.get('flashMessages').danger('This css is not valid');
        });
      }
    },


    actions: {
      change: function change() {
        var serialized = this.get('model').serialize();
        if (JSON.stringify(serialized) !== JSON.stringify(this.get('cached'))) {
          this.set('updating', true);
        }
        Ember.run.debounce(this, this.updateCss, 750);
      }
    }
  });
});