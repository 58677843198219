define('lcms/models/base/sites/site', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    is_multipage: (0, _attr.default)('boolean'),

    available_languages: (0, _attr.default)(),

    css: (0, _attr.default)('string'),

    brand_primary: (0, _attr.default)('string'),

    brand_secondary: (0, _attr.default)('string'),

    font_family_base: (0, _attr.default)('string'),

    font_size: (0, _attr.default)('number'),

    headings_font_family: (0, _attr.default)('string'),

    text_color: (0, _attr.default)('string'),

    body_bg: (0, _attr.default)('string'),

    link_color: (0, _attr.default)('string'),

    custom_scss: (0, _attr.default)('string'),

    __str__: (0, _attr.default)('string')

  });
});