define('lcms/models/lcms/content', ['exports', 'lcms/models/base/lcms/content'], function (exports, _content) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _content.default.extend({
    sortImagesBy: ['ordernum'],
    sortedImages: Ember.computed.sort('images', 'sortImagesBy'),
    content_type: Ember.computed('type.name', function () {
      var type = this.get('type');
      if (type) {
        return '' + this.get('type.name');
      } else {
        return 'text';
      }
    }),

    component: Ember.computed('content_type', function () {
      var ct = this.get('content_type');
      if (!Ember.isEmpty(ct) && ct !== 'undefined') {
        return 'content-' + ct;
      } else {
        return 'content-text';
      }
    })
  });
});