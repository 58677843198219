define('lcms/models/base/lcms/contenttype', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    name: (0, _attr.default)('string'),

    fields: (0, _attr.default)(),

    has_image: (0, _attr.default)('boolean'),

    has_file: (0, _attr.default)('boolean'),

    has_media: (0, _attr.default)('boolean'),

    __str__: (0, _attr.default)('string')

  });
});