define('lcms/components/language-switch/component', ['exports', 'lcms/mixins/request'], function (exports, _request) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_request.default, {
    store: Ember.inject.service(),

    current: null,

    langs: Ember.computed('site', 'site.available_languages', function () {
      var rv = [];
      var languages = this.get('site.available_languages');
      if (languages) {
        languages.forEach(function (language) {
          var l = language.split('-');
          rv.push({
            orig: language,
            label: l[0],
            flag: l.length > 1 ? l[1] : l[0]
          });
        });
      }
      return rv;
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this.makeRequest(this.serverAuthEndpoint + '/../lang/', undefined, 'GET').then(function (response) {
        _this.set('current', response.lang);
      });
    },


    actions: {
      reload_for_lang: function reload_for_lang(lang) {
        var _this2 = this;

        this.makeRequest(this.serverAuthEndpoint + '/../lang/', { lang: lang }).then(function (response) {
          _this2.set('current', lang);
          ['lcms/content', 'lcms/section', 'lcms/page', 'sites/site'].forEach(function (model) {
            _this2.get('store').findAll(model, { reload: true });
          });
        });
      }
    }
  });
});