define('lcms/components/list-pagination', ['exports', 'ember-cli-crudities/components/list-pagination'], function (exports, _listPagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _listPagination.default;
    }
  });
});