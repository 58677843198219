define('lcms/helpers/conditional-format', ['exports', 'ember-cli-crudities/helpers/conditional-format'], function (exports, _conditionalFormat) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _conditionalFormat.default;
    }
  });
  Object.defineProperty(exports, 'conditionalFormat', {
    enumerable: true,
    get: function () {
      return _conditionalFormat.conditionalFormat;
    }
  });
});