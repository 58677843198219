define('lcms/components/toolbar-field/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Component.extend({
    prefix: '',
    currentLanguage: '',

    change: function change() /*prop, value*/{
      // placeholder
    },
    focusOut: function focusOut() /*prop, value*/{
      // placeholder
    },


    actions: {
      change: function change(prop, value) {
        this.change(prop, value);
        var l = this.get('prefix.length');
        if (l > 0) {
          console.log('change called');
          var currentLanguage = this.get('currentLanguage');
          var name = prop.substring(l, prop.length);
          var field = this.get('prefix').substring(0, l - 1);
          var data = this.get(field);
          var newVal = _extends({}, data);
          newVal['' + name + currentLanguage] = value.trim();
          this.get('model').send('didSetProperty', { name: field, value: newVal });
          // Ember.run.later(this, () => {
          //   Ember.set(this, `model.${field}`, newVal);
          // }, 150);
        }
      }
    }
  });
});