define('lcms/initializers/django', ['exports', 'ember-cli-js-cookie'], function (exports, _emberCliJsCookie) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  function csrfSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method)
    );
  }

  function initialize() /* application */{
    // just in case someone wants to use window.$
    // instead of fetch
    if (window.$) {
      var csrftoken = _emberCliJsCookie.default.get('csrftoken');
      window.$.ajaxSetup({
        beforeSend: function beforeSend(xhr, settings) {
          if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
            xhr.setRequestHeader("X-CSRFToken", csrftoken);
          }
        },
        complete: function complete() /* xhr, status */{
          var tmp_csrftoken = _emberCliJsCookie.default.get('csrftoken');
          if (tmp_csrftoken !== undefined && tmp_csrftoken.length) {
            csrftoken = tmp_csrftoken;
          }
          var tmp_sessionid = _emberCliJsCookie.default.get('sessionid');
          if (tmp_sessionid !== undefined && tmp_sessionid) {
            _emberCliJsCookie.default.set('sessionid', tmp_sessionid);
          }
        }
      });
    }
  }

  exports.default = {
    name: 'django',
    initialize: initialize
  };
});