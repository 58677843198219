define('lcms/components/settings-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    fields: [{
      widget: 'checkbox',
      label: 'Has anchors menu',
      name: 'has_anchors_menu'
    }, {
      name: 'keywords',
      label: 'Keywords',
      widget: 'textarea'
    }, {
      name: 'description',
      label: 'Description',
      widget: 'textarea'
    }]
  });
});