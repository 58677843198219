define('lcms/data/index', ['exports', 'lcms/data/sites/site', 'lcms/data/lcms/page', 'lcms/data/lcms/content', 'lcms/data/lcms/contentmedium', 'lcms/data/lcms/contenttype', 'lcms/data/lcms/section'], function (exports, _site, _page, _content, _contentmedium, _contenttype, _section) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.metadata = undefined;
  var metadata = exports.metadata = new Ember.Object({ "applications": [{ "models": [{ "singular": "page", "endpoint": "lcms/pages", "name": "pages" }, { "singular": "section", "endpoint": "lcms/sections", "name": "sections" }, { "singular": "contenttype", "endpoint": "lcms/contenttypes", "name": "contenttypes" }, { "singular": "content", "endpoint": "lcms/contents", "name": "contents" }, { "singular": "contentmedium", "endpoint": "lcms/contentmedia", "name": "contentmedia" }], "name": "lcms" }, { "models": [{ "singular": "site", "endpoint": "sites/sites", "name": "sites" }], "name": "sites" }], "languages": ["en-gb", "fr-fr"], "endpoints": ["lcms/pages", "lcms/sections", "lcms/contenttypes", "lcms/contents", "lcms/contentmedia", "sites/sites"] }); /* eslint-disable */
  exports.default = new Ember.Object({

    'sites/sites': new Ember.Object(_site.default),

    'lcms/pages': new Ember.Object(_page.default),

    'lcms/contents': new Ember.Object(_content.default),

    'lcms/contentmedia': new Ember.Object(_contentmedium.default),

    'lcms/contenttypes': new Ember.Object(_contenttype.default),

    'lcms/sections': new Ember.Object(_section.default)

  });
});