define('lcms/mixins/editable-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    lcms: Ember.inject.service(),
    type: null,

    classNameBindings: ['border', 'reordering'],

    border: Ember.computed('model', 'editing', 'model.dirtyBorder', function () {
      var model = this.get('model');
      var editing = this.get('editing');
      var rv = void 0;
      if (editing === model) {
        rv = 'border-editing';
      } else if (model.get('dirtyBorder') === true) {
        rv = 'border-unsaved';
      } else {
        rv = 'border-none';
      }
      return Ember.String.htmlSafe(rv);
    }),

    reordering: Ember.computed.alias('lcms.reordering'),

    actions: {
      toggle: function toggle(model) {
        var _this = this;

        if (this.get('lcms.edited') === null || this.get('lcms.edited') === undefined) {
          this.set('editing', model);
          this.set('lcms.edited', this.get('type'));
          return;
        }
        var editing = this.get('editing');
        if (!Ember.isEmpty(editing)) {
          editing.set('dirtyBorder', editing.get('isNew') || editing.get('hasDirtyArttibutes') || editing.get('hasDirtyRelations') || this.get('type') === 'content' && editing.didChange('content_data'));
        }
        if (editing !== model) {
          this.set('lcms.edited', 'none');
          Ember.run.later(this, function () {
            _this.set('editing', model);
            _this.set('edited', _this.get('type'));
          }, 260);
        } else {
          this.set('edited', null);
        }
        this.set('editing', undefined);
      }
    }
  });
});