define('lcms/models/base/lcms/section', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    type: (0, _attr.default)('string'),

    category: (0, _attr.default)('string'),

    slug: (0, _attr.default)('string'),

    title: (0, _attr.default)('string'),

    klass: (0, _attr.default)('string'),

    background_type: (0, _attr.default)('string'),

    background_color: (0, _attr.default)('string'),

    background_image: (0, _attr.default)('string'),

    background_attachment: (0, _attr.default)('string'),

    background_size: (0, _attr.default)('string'),

    background_repeat: (0, _attr.default)('string'),

    background_container_size: (0, _attr.default)('string'),

    ordernum: (0, _attr.default)('number'),

    __str__: (0, _attr.default)('string'),

    contents: (0, _relationships.hasMany)('lcms/content', {
      async: true,
      inverse: 'section'
    }),

    page: (0, _relationships.belongsTo)('lcms/page', {
      async: true,
      inverse: 'sections'
    })

  });
});