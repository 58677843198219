define('lcms/mixins/request', ['exports', 'ember-network/fetch', 'ember-cli-js-cookie', 'lcms/config/environment'], function (exports, _fetch, _emberCliJsCookie, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function isSecureUrl(url) {
    var link = document.createElement('a');
    link.href = url;
    link.href = link.href;
    return link.protocol === 'https:';
  }

  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super();
      var globalConfig = _environment.default['ember-simple-auth'] || {};
      this.apiRoot = _environment.default.APP.API_NAMESPACE;
      this.serverAuthEndpoint = globalConfig.serverAuthEndpoint || '/rest-auth';
    },
    makeRequest: function makeRequest(url, data, method) {
      if (!isSecureUrl(url)) {
        Ember.Logger.warn('Credentials are transmitted via an insecure connection - use HTTPS to keep them secure.');
      }
      if (method === undefined) {
        method = 'POST';
      }
      var params = {
        method: method,
        headers: {
          "X-CSRFToken": _emberCliJsCookie.default.get('csrftoken'),
          "Content-Type": 'application/json',
          "Accept": 'application/json'
        }
      };
      if (method === 'POST') {
        params['body'] = JSON.stringify(data);
      } else if (method === 'GET' && window.$ !== undefined) {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          window.$.get(url, function (xhr) {
            resolve(xhr);
          }).fail(function () {
            reject();
          });
        });
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        (0, _fetch.default)(url + '?format=json', params).then(function (response) {
          if (response.status === 400) {
            response.json().then(function (json) {
              reject(json);
            });
          } else if (response.status > 400) {
            reject(response);
          } else {
            resolve(response);
          }
        }).catch(function (err) {
          reject(err);
        });
      });
    }
  });
});