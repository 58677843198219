define('lcms/components/liquid-append', ['exports', 'liquid-wormhole/components/liquid-append'], function (exports, _liquidAppend) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _liquidAppend.default;
    }
  });
});