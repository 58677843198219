define('lcms/models/lcms/page', ['exports', 'lcms/models/base/lcms/page'], function (exports, _page) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _page.default.extend({
    sortSectionsBy: ['ordernum'],
    sortedSections: Ember.computed.sort('sections', 'sortSectionsBy')
  });
});