define('lcms/components/lcms-menu/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    lcms: Ember.inject.service(),
    modelLoader: Ember.inject.service(),
    store: Ember.inject.service(),

    classNames: ['container-fluid', 'navbar', 'navbar-default'],

    init: function init() {
      var _this = this;

      this._super();
      this.get('modelLoader').ensure_model('sites', 'site', 'sites').then(function () {
        _this.get('store').queryRecord('sites/site', {}).then(function (site) {
          _this.set('site', site);
        });
      });
    },


    actions: {
      switchToDesign: function switchToDesign() {
        var lcms = this.get('lcms');
        lcms.set('edited', 'none');
        lcms.set('editing', undefined);
        Ember.run.later(this, function () {
          lcms.set('edited', 'design');
        }, 260);
      }
    }
  });
});