define('lcms/components/cancel-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      rollback: function rollback() {
        if (this.get('model.isNew')) {
          this.get('model').rollbackAttributes();
          this.set('edited', null);
        } else {
          this.get('model').rollback();
        }
      }
    }
  });
});