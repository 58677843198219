define('lcms/components/content-contactform/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sortBy: ['position'],
    orderedFields: Ember.computed.sort('model.content_data.fields', 'sortBy'),

    actions: {
      none: function none() {
        // do nothing
      }
    }
  });
});