define('lcms/components/editable-content/component', ['exports', 'lcms/mixins/editable-mixin'], function (exports, _editableMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_editableMixin.default, {
    lcms: Ember.inject.service(),
    type: 'content',
    classNames: ['content-item'],
    classNameBindings: ['size', 'special'],

    special: Ember.computed.alias('model.special'),

    size: Ember.computed('lcms.reordering', 'model.size', 'model.section.type', function () {
      if (this.get('lcms.reordering')) {
        return '';
      }
      if (this.get('model.section.type.length') > 1) {
        return 'card';
      }
      return this.get('model.size');
    })
  });
});