define('lcms/authorizers/django', ['exports', 'ember-simple-auth/authorizers/base', 'ember-cli-js-cookie'], function (exports, _base, _emberCliJsCookie) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    authorize: function authorize(sessionData, block) {
      var csrftoken = _emberCliJsCookie.default.get('csrftoken');
      block('X-CSRFToken', csrftoken);
    }
  });
});