define('lcms/models/base/lcms/page', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    title: (0, _attr.default)('string'),

    slug: (0, _attr.default)('string'),

    keywords: (0, _attr.default)('string'),

    description: (0, _attr.default)('string'),

    author_name: (0, _attr.default)('string'),

    author_email: (0, _attr.default)('string'),

    has_anchors_menu: (0, _attr.default)('boolean'),

    __str__: (0, _attr.default)('string'),

    sections: (0, _relationships.hasMany)('lcms/section', {
      async: true,
      inverse: 'page'
    }),

    site: (0, _relationships.belongsTo)('sites/site', {
      async: true,
      inverse: null
    })

  });
});