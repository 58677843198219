define('lcms/components/user-info/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    right: false,
    login: null,
    password: null,

    authenticateAction: 'authenticate',
    invalidateAction: 'invalidate',

    actions: {
      authenticate: function authenticate() {
        var credentials = {
          identification: this.get('login'),
          password: this.get('password')
        };
        this.sendAction('authenticateAction', credentials);
      },
      invalidate: function invalidate() {
        this.sendAction('invalidateAction');
      }
    }
  });
});