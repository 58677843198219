define('lcms/components/page-section/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    lcms: Ember.inject.service(),
    classNames: ['section-wrapper'],
    classNameBindings: ['_container', 'outerFlex', 'sectionClass'],
    attributeBindings: ['style'],

    sectionClass: Ember.computed.alias('model.klass'),

    _container: Ember.computed('model.type', 'hasContainerWrapper', function () {
      var type = this.get('model.type');
      if (type === undefined) {
        return '';
      }
      if (this.get('hasContainerWrapper') === true) {
        return '';
      }
      var inc = 0;
      if (this.get('model.type.length') == 2) {
        inc = 1;
      }

      var rv = type.substring(0 + inc, 1 + inc) == 'F' ? 'container-fluid' : 'container';
      return Ember.String.htmlSafe(rv);
    }),

    containerStyle: Ember.computed('model.background_type', 'model.background_attachment', 'model.background_image', 'model.background_color', 'model.background_size', 'model.background_repeat', function () {
      var bgType = this.get('model.background_type');
      var rv = void 0;
      if (bgType == 'C') {
        rv = 'background-color: ' + this.get('model.background_color') + ';';
      } else if (bgType == 'I') {
        rv = 'background-repeat: ' + this.get('model.background_repeat') + '; background-position: ' + this.get('model.background_attachment') + '; background-size: ' + this.get('model.background_size') + '; background-image: url(' + this.get('model.background_image') + ');';
      } else {
        rv = '';
      }
      return Ember.String.htmlSafe(rv);
    }),

    flex: Ember.computed('model.type', function () {
      if (this.get('lcms.reordering')) {
        return 'no-flex-section';
      }
      var typeLength = this.get('model.type.length');
      return typeLength == 1 ? 'no-flex-section' : 'flex-section';
    }),

    outerFlex: Ember.computed('hasContainerWrapper', 'flex', function () {
      if (this.get('hasContainerWrapper')) {
        return Ember.String.htmlSafe('');
      }
      return this.get('flex');
    }),

    style: Ember.computed('hasContainerWrapper', 'model.background_container_size', 'containerStyle', function () {
      if (this.get('hasContainerWrapper') && this.get('model.background_container_size') === 'contained') {
        return Ember.String.htmlSafe('');
      }
      return this.get('containerStyle');
    }),

    hasContainerWrapper: Ember.computed('model.type', 'model.background_type', 'model.background_container_size', function () {
      var type = this.get('model.type');
      if (type === 'F' || type === 'FF') {
        return false;
      }
      if (this.get('model.background_container_size') === 'contained') {
        return false;
      }
      if (this.get('model.background_type') === 'N') {
        return false;
      }
      return true;
    })
  });
});