define('lcms/services/session', ['exports', 'lcms/config/environment', 'ember-simple-auth/services/session', 'ember-network/fetch', 'ember-cli-js-cookie'], function (exports, _environment, _session, _fetch, _emberCliJsCookie) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var observer = Ember.observer;
  exports.default = _session.default.extend({
    currentUser: null,
    loginError: false,
    setCurrentUser: observer('isAuthenticated', function () {
      var _this = this;

      if (this.get('isAuthenticated')) {
        var globalConfig = _environment.default['ember-simple-auth'] || {};
        var serverAuthEndpoint = globalConfig.serverAuthEndpoint || '/rest-auth';
        var url = serverAuthEndpoint + '/me/?format=json';
        if (window.$ !== undefined) {
          window.$.get(url, function (xhr) {
            _this.set('currentUser', xhr);
          }).fail(function () {
            _this.set('isAuthenticated', false);
          });
        } else {
          var params = {
            headers: {
              "X-CSRFToken": _emberCliJsCookie.default.get('csrftoken'),
              "Content-Type": 'application/json',
              "Accept": 'application/json'
            }
          };
          (0, _fetch.default)(url, params).then(function (response) {
            return response.json();
          }, function () {
            _this.set('isAuthenticated', false);
          }).then(function (json) {
            _this.set('currentUser', json);
          }, function () {
            console.log('failed parsing reponse for current user');
            _this.set('isAuthenticated', false);
          });
        }
      } else {
        this.set('currentUser', '');
      }
    }),

    invalidate: function invalidate() {
      this._super();
      this.set('currentUser', undefined);
      var globalConfig = _environment.default['ember-simple-auth'] || {};
      var serverAuthEndpoint = globalConfig.serverAuthEndpoint || '/rest-auth';
      var url = serverAuthEndpoint + '/logout/';
      if (window.$ !== undefined) {
        window.$.get(url);
      } else {
        var params = {
          headers: {
            "X-CSRFToken": _emberCliJsCookie.default.get('csrftoken'),
            "Content-Type": 'application/json',
            "Accept": 'application/json'
          }
        };
        (0, _fetch.default)(url, params);
      }
    }
  });
});