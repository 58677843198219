define('lcms/components/edit-toolbar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    fields: [{
      widget: 'select',
      label: 'Size',
      name: 'size',
      extra: {
        choices: [{
          value: 'col-xs-12',
          label: 'Full-width'
        }, {
          value: 'col-md-6 col-xs-12',
          label: 'Half-width'
        }, {
          value: 'col-lg-4 col-sm-6 col-xs-12',
          label: 'Third-width'
        }, {
          value: 'col-lg-3 col-sm-6 col-xs-12',
          label: 'Fourth-width'
        }],
        display_condition: {
          operator: 'in',
          property_path: 'section.type',
          value: ['C', 'F']
        }
      }
    }, {
      widget: 'foreignkey',
      label: 'Content type',
      name: 'type',
      extra: {
        related_model: 'lcms/contenttype'
      }
    }, {
      widget: 'text',
      label: 'Special class',
      name: 'special'
    }, {
      label: 'Content image',
      name: 'content_image',
      widget: 'image',
      extra: {
        display_condition: {
          operator: 'eq',
          property_path: 'type.has_image',
          value: true
        }
      }
    }, {
      label: 'Content file',
      name: 'content_file',
      widget: 'file',
      extra: {
        display_condition: {
          operator: 'eq',
          property_path: 'type.has_file',
          value: true
        }
      }
    }],

    imageField: {
      has_label: false,
      name: 'image',
      widget: 'image'
    },

    unsaved: Ember.computed('model', 'model.content_data.@each', 'model.isDirty', 'model.hasDirtyRelations', 'model.isNew', function () {
      var model = this.get('model');
      if (model === undefined) {
        return false;
      }
      return model.get('isNew') || model.get('isDirty') || model.get('hasDirtyRelations') || model.didChange('content_data');
    }),

    actions: {
      addImage: function addImage() {
        var record = this.get('store').createRecord('lcms/contentmedium', {
          ordernum: this.get('model.images.length'),
          content: this.get('model')
        });
      },
      remImage: function remImage(image) {
        if (image.get('isNew')) {
          image.rollbackAttributes();
        } else {
          image.set('softDeleted', !image.get('softDeleted'));
        }
      },
      reorderImages: function reorderImages(items) {
        var index = 0;
        items.forEach(function (item) {
          item.set('ordernum', index);
          index++;
        });
      }
    }
  });
});